import React from "react"
//import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from "gatsby"

class Header extends React.Component {

    render() {
        return (

            <div className="col-lg-4 col-md-12 sidenav">
                <div className="sidebar sticky-top sdbr">
                    {this.props.tagName}
                    <div className="subtt">
                    บริการข้อมูลโดยไม่มีค่าใช้จ่ายเกี่ยวกับการซื้อขายเรียลไทม์ พอร์ตการลงทุน แผนภูมิสตรีมมิ่ง ข่าวการเงิน ข้อมูลตลาดหุ้นแบบสดและอีกมากมาย
                    </div>
                    <div className="listmn">
                        <a href="/about">เกี่ยวกับเรา</a>
                        <a href="#">โบรกเกอร์ที่ดีที่สุดในประเทศไทย</a>
                        <ul>
                        <li><a className="fht" href="#">IQ Option</a></li> 
                        <li><a className="fht" href="#">Olymp Trade</a></li> 
                        <li><a className="fht" href="#">eToro</a></li> 
                        </ul>
                        <a href="/privacy-policy">นโยบายความเป็นส่วนตัว</a>
                        <a href="/risk-statement">ความเสี่ยงและข้อกำหนดการใช้งาน</a>
                    </div>
                    <div className="fht">
                    © สงวนลิขสิทธิ์
                    </div>
                </div>
            </div>

        )
    }
}

export default Header;
