import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
//import Siderbar from "./Sidebar";
//import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";
import logo from './streamlineforex.png'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1>
          <Link className="nava_logo" to="/">
            <img className="nav_logo_img" src={logo} alt="Streamline Forex" />
            <p className="pth">Streamline Forex</p>
          </Link>
        </h1>
      )
    } else {
      header = (
        <span>
          <Link className="nava_logo" to="/">
            <img className="nav_logo_img" src={logo} alt="CAS Trader" />
          </Link>
        </span>
      )
    }
    return (
      <Fragment>
        {/* <Header tagName={header} /> */}
        <div className="page_wrapper container">
          <div className="row">
            <Header tagName={header} />
            {children}
          </div>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

export default Layout
