import React from 'react'
import { Link, graphql } from 'gatsby'
//import Img from "gatsby-image"
import SEO from '../components/seo'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Button } from 'react-bootstrap'
import { SlugLink } from '../components/utils'
//import Sidebar from '../components/Sidebar'
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import 'react-lazy-load-image-component/src/effects/blur.css';


class BlogIndex extends React.Component {
    renderTags(tags) {
        tags.map((item) => {
            //console.log(item)
            return <Button variant="outline-secondary">{item}</Button>
        })
    }
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const siteDescription = data.site.siteMetadata.description
        const domainName = data.site.siteMetadata.domainName
        //  const siteUrl = data.site.siteMetadata.siteUrl
        const siteUrl = 'https://streamlineforex.com'
        const posts = data.allMarkdownRemark.edges
        const { currentPage, numPages } = this.props.pageContext
        let curr = 1;
        if (currentPage === undefined) { curr = 1 }
        else { curr = currentPage }
        const isFirst = curr === 1
        const isLast = curr === numPages
        const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
        const nextPage = (curr + 1).toString()
        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={`${siteTitle} ${currentPage === 1 ? '' : `- Page ${currentPage}`}`}
                    description={`${siteDescription} ${currentPage === 1 ? '' : `- Page ${currentPage}`}`}
                    canonical={`https://${domainName}`}
                />

                <div className="col-lg-8 p1030">
                    
                        {posts.map(({ node }) => {
                        //    let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid.src
                            const title = node.frontmatter.title || node.fields.slug
                            return (
                                
                                    <article className="row no-gutters post-id-single psdd" key={node.fields.slug}>

                                        <div className="col-lg-12 col-md-12 post-id_blurb">
                                            <div className="d-flex justify-content-start post-id_card">

                                                <div className="post-id_name">

                                                    <span className="post-id_published">
                                                        <time>{node.frontmatter.date}</time>
                                                    </span>
                                                    <span className="thecategory">
                                                        {node.frontmatter.category !== null ? <a href={siteUrl + `/category/${SlugLink(node.frontmatter.category)}`}>{node.frontmatter.category}</a> : 'Other'}
                                                    </span>
                                                </div>
                                            </div>
                                            <a className="post-id_link" href={node.fields.slug}>
                                                <h2 className="post-id_headline-with-image">
                                                    {title}
                                                </h2>
                                            </a>

                                            <div className="post-id_desc">
                                                <div className="post-id_subhead" dangerouslySetInnerHTML={{ __html: node.frontmatter.description }} />
                                            </div>
                                            <div className="rmtc">
                                                <a className="rmt" href={node.fields.slug}>
                                                    Read
                                            </a>
                                            </div>
                                        </div>
                                    </article>
                                
                            )
                        })}
                        <div className="col-lg-12 col-md-12 psdd">
                            <div className="row d-flex justify-content-center pagi-wrap">
                                {!isFirst && (
                                    <div className="pagi__link">
                                        <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                                            ←
                </Link>
                                    </div>
                                )}
                                {Array.from({ length: numPages }, (_, i) => {
                                    if (i < curr + 3 && i > curr - 3) {
                                        return (
                                            <div
                                                key={`pagination-number${i + 1}`}
                                                className="pagi__link"
                                            >
                                                <Link
                                                    to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                                                    className={i + 1 === curr ? "current" : "na-pagi"}
                                                >
                                                    {i + 1}
                                                </Link>
                                            </div>
                                        )
                                    }
                                })}
                                {!isLast && (
                                    <div className="pagi__link">
                                        <Link to={`/page/${nextPage}`} rel="next">
                                            →
                </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    
                </div>

            </Layout >
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
query blogPageQuery($skip: Int!, $limit: Int!, $curdate: Date) {
    site {
      siteMetadata {
        title
        domainName
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {frontmatter: {date: {lt: $curdate}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            description
            tags
            category
            cover {
                childImageSharp {
                    fluid {
                        src
                      }
                }
              }
          }
          html
          timeToRead
        }
      }
    }
  }
`
